<template>
  <div class="login" v-loading="loading">
    <img class="back" src="@/assets/imgs/log/bjd.png" alt="" />
    <div class="content">
      <div class="content_box">
        <img src="@/assets/imgs/log/log.png" alt="" />
        <div class="inedx">
          <input
            type="text"
            v-model="loginForm.account"
            placeholder="请输入手机账号"
          />
          <input
            type="password"
            v-model="loginForm.password"
            placeholder="请输入密码"
          />
          <input
            type="password"
            v-model="loginForm.conpassword"
            placeholder="请确认密码"
          />

          <div class="button">
            <button @click="login">注册</button>
          </div>
          <a href="#/login">
            <p class="p">已有帐号<samp>去登录？</samp></p></a
          >
        </div>
        <img class="pos" src="@/assets/imgs/log/account.png" alt=""/>
        <img class="posi" src="@/assets/imgs/log/lock.png" alt="" />
        <img class="posj" src="@/assets/imgs/log/lock.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import VueCookies from "vue-cookies"; // 设置cookies
export default {
  data() {
    return {
      loginForm: {
        account: "111546464",
        password: "123",
        conpassword: "123",
      },
      loading: false,
    };
  },

  methods: {
    ...mapMutations(["changeLogin"]),
    login() {
      if (this.loginForm.account === "" || this.loginForm.password === "")
        return this.toast("账号或密码不能为空！");

      console.log(this.loginForm.password);
      console.log(this.loginForm.conpassword);
      if (this.loginForm.password === this.loginForm.conpassword)
        return this.toast("密码不一致！");
      this.loading = true;
      this.$ajax
        .login(this.loginForm)
        .then((res) => {
          this.loading = false;
          this.toast(res.msg, res.conpassword == 200);
          if (res.conpassword != 200) return;
          VueCookies.set("tokenid", res.data.token, 2 * 60 * 60 * 1000);
          this.$router.push("/log");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  position: relative;
  .back {
    width: 100%;
    height: 100vh;
  }
  .content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    .content_box {
      max-width: 620px;
      height: 450px;
      border-radius: 20px;
      border: 1px solid #fff;
      overflow: hidden;
      margin: 0 auto;
      margin-top: 10%;
      background: rgba(255, 255, 255, 0.2);
      text-align: center;
      img {
        margin-top: 40px;
      }
      .inedx {
        width: 365px;
        height: 300px;
        margin: 0 auto;
        margin-top: 50px;
        // background: royalblue;
        ::-webkit-input-placeholder {
          color: #fff;
        }
        　　 input:nth-child(1) {
          width: 365px;
          height: 25px;
          background: none;
          outline: none;
          border: none;
          border-bottom: 1px solid #fff;
          font-size: 17px;
          padding-bottom: 5px;
          letter-spacing: 2px;
          color: #fff;
          text-indent: 30px;
        }
        　　 input:nth-child(2) {
          width: 365px;
          height: 25px;
          background: none;
          outline: none;
          border: none;
          border-bottom: 1px solid #fff;
          letter-spacing: 2px;
          margin: 50px 0 0px 0;
          padding-bottom: 5px;
          font-size: 17px;
          color: #fff;
          text-indent: 30px;
        }
        　　 input:nth-child(3) {
          width: 365px;
          height: 25px;
          background: none;
          outline: none;
          border: none;
          border-bottom: 1px solid #fff;
          letter-spacing: 2px;
          margin: 50px 0 0px 0;
          padding-bottom: 5px;
          font-size: 17px;
          color: #fff;
          text-indent: 30px;
          margin-bottom: 30px;
        }

        .button {
          width: 100%;
          height: 40px;
          overflow: hidden;
          button {
            border: 1px solid #fff;
            border-radius: 5px;
            font-size: 17px;
            font-family: unset;
            font-weight: 600;
            color: #fff;
            letter-spacing: 2px;
            background: rgba(255, 255, 255, 0.2);
            padding: 8px 30px;
            outline: none;
          }
        }
        .p {
          margin-top: 10px;
          font-size: 17px;
          font-family: unset;
          font-weight: 600;
          color: #fff;
          letter-spacing: 2px;
          float: right;
          samp {
            font-size: 17px;
            font-family: unset;
            font-weight: 600;
            color: #fff;
            letter-spacing: 2px;
            cursor: pointer;
          }
          samp:hover {
            color: #ef505e;
          }
        }
      }
      .pos {
        position: relative;
        top: -335px;
        left: -160px;
      }
      .posi {
        position: relative;
        top: -253px;
        left: -173px;
      }
      .posj {
        position: relative;
        top: -172px;
        left: -187px;
      }
    }
  }
}
</style>
